<template>
    <div class="bbb">
      <Header/>
      <div id="Interrogation" class="content time_slot">
        <leftNavigationBar/>
        <div class="content_">
          <div>
            <div class="search">
            <div>
              <el-button class="refresh" @click="loadList" icon="el-icon-refresh">刷新</el-button>
              <el-button-group style="margin-left: 10px;">
                    <el-button type="primary"  @click="getdate">今天问诊</el-button>
                    <el-button type="primary" @click="date = '';loadList()">全部问诊</el-button>
                </el-button-group>
            </div>
            <div class="roundInp">
                <el-input
                    placeholder="患者姓名/取药码"
                    v-model="input">
                    <el-button slot="append" @click="search" icon="el-icon-search"></el-button>
                </el-input>
          </div>
          </div>
          <div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="全部" name="all"></el-tab-pane>
              <el-tab-pane label="待审核" name="wait"></el-tab-pane>
              <el-tab-pane label="已审核" name="adopt"></el-tab-pane>
              <el-tab-pane label="已取消" name="quit"></el-tab-pane>
            </el-tabs>
          </div>
          <div>
            <el-table
                :data="tableData"
                :row-class-name="tableRowClassName"
            >
              <el-table-column
                  prop="name"
                  label="患者姓名"
              >
                <template slot-scope="scope">{{scope.row.Patient[0].name}}</template>
              </el-table-column>
              <el-table-column
                  prop="pres_id"
                  label="处方单号"
              >
              </el-table-column>
              <el-table-column
                prop="DRGDatabas"
                label="处方疾病"
              >
              </el-table-column>
              <!-- <el-table-column
                  prop="doctor"
                  label="处方药品"
              >
              <template slot-scope="scope">{{scope.row.PublicDrugs.toString()}}</template>
              </el-table-column> -->
              <el-table-column
                  prop="created_at"
                  label="订单时间"
              >
              </el-table-column>
              <el-table-column
                  prop="price"
                  label="订单金额（元）"
              >
              <template slot-scope="scope">{{scope.row.price =='None' ? '无' : `${scope.row.price}元`}}</template>
              </el-table-column>
              <el-table-column
                  prop=""
                  label="医生审核"
              >
                <template slot-scope="scope">
                    {{scope.row.doctorId == 'None' ? '待接单' : '已接单'}}
                </template>
              </el-table-column>
              <el-table-column
                  prop=""
                  label="药师审核"
              >
                <template slot-scope="scope">{{Status[scope.row.pharmacistStatus*1]}}</template>
              </el-table-column>
             
              <el-table-column
                  prop=""
                  label="处方状态"
              >
              <template slot-scope="scope">{{state[scope.row.state]}}</template>   
              </el-table-column>
              <el-table-column
                  prop=""
                  label="取药码"
              >
              <template slot-scope="scope">{{fromType(scope.row)}}</template>   
              </el-table-column>
              <el-table-column
                  label="操作"
              >
                <template slot-scope="scope">
                    <el-button @click="chatCli(scope.row)"  style="margin-left: 10px;" type="text" slot="reference">找运营</el-button>
                    <el-button @click="chakan(scope.row,'订单详情')"  style="margin-left: 10px;" type="text" slot="reference">查看详情</el-button>
                    <!-- <el-button v-if="scope.row.state == 'adopt'"  @click="generatePDF(scope.row.presImgUrl)" style="margin-left: 10px;"  type="text" slot="reference">打印处方</el-button> -->
                    <el-button v-if="scope.row.state == 'adopt'"  @click="getpresImgUrl(scope.row,'view')" style="margin-left: 10px;"  type="text" slot="reference">查看处方</el-button>
                    <!-- <a v-if="scope.row.state == 'adopt'" :href="scope.row.presImgUrl"><el-button  style="margin-left: 10px;"  type="text" slot="reference">查看处方</el-button></a> -->
                </template>
              </el-table-column>
            </el-table>
          </div>
          </div>
        </div>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
        <el-dialog
        :title="title"
        :visible.sync="drawer"
        width="50%"
        direction="rtl"
        :before-close="handleClose">
        <el-form ref="form" :model="form" label-position="top" label-width="100px">
            <el-form-item label="订单信息">
            <el-descriptions class="margin-top"  :column="3"  border>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-user"></i>
                        患者姓名
                    </template>
                    {{form.Patient[0].name}}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-mobile-phone"></i>
                        手机号
                    </template>
                    {{ form.Patient[0].phone }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-female"></i>
                        性别
                    </template>
                    {{form.Patient[0].sex == '0' ? '女' : '男'}}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-office-building"></i>
                        处方单号
                    </template>
                    {{form.pres_id}}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-coordinate"></i>
                        收件人信息
                    </template>
                    {{form.Patient[0].name}} {{ form.Patient[0].phone }} {{ form.Patient[0].address }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-edit"></i>
                        描述
                    </template>
                    {{form.conditions}}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-coin"></i>
                        症状
                    </template>
                    <span v-for="(item,index) of form.DRGDatabase_name">
                        {{ item.name }}
                    </span>
                </el-descriptions-item>
            </el-descriptions>

          </el-form-item>
          <el-form-item label="药品信息">
            <el-table
                :data="form.Drugs"
            >
              <el-table-column
                  prop="name"
                  label="药品名称"
              >
              <template slot-scope="scope">{{scope.row.name}}</template>
              </el-table-column>
              <el-table-column
                  prop="drugcode"
                  label="医保编码"
              >
              </el-table-column>
              <el-table-column
                  prop="specs"
                  label="厂家规格"
              >
              </el-table-column>
              <el-table-column
                  prop="zongliang"
                  label="开具总量"
              >
              <template slot-scope="scope">
                {{ 
                    
                    form.medicine[form.medicine.findIndex(item=>{return scope.row.id == item.skuId})].total
                }}
              </template>
              </el-table-column>
            </el-table>
          
          </el-form-item>
         
          
          <el-form-item v-if="title !=='订单详情'">
            <div style="display: flex; justify-content: space-between;">
              <el-button round @click="drawer = false">取 消</el-button>
              <el-button type="primary" round @click="PresPut">确 认</el-button>
            </div>
          </el-form-item>
          

        </el-form>
      </el-dialog>
      <el-dialog
        title="聊天"
        :visible.sync="chat_show"
        width="50%"
        direction="rtl"
        >
        <JwChat-index
                :taleList="chatLIst"
                v-model="inputMsg"
                @enter="bindEnter"
                :config="config"
                scrollType="scroll"
                :showRightBox="false"
                :toolConfig="toolConfig"
              
            >
                </JwChat-index>
      </el-dialog>
      </div>


      <el-dialog
        title="查看处方"
        :visible.sync="presDrawer"
        width="30%"
        direction="rtl"
        :before-close="handleClose">
        <el-table
                :data="presInfo"
            >
                <el-table-column
                        label="序号"
                    >
                <template slot-scope="scope">{{scope.$index+1}}</template>
              </el-table-column>
              <el-table-column
                  label="处方CODE"
              >
                <template slot-scope="scope">{{scope.row.code}}</template>
              </el-table-column>
              <el-table-column
                  label="操作"
              >
                <template slot-scope="scope">
                    <el-button   @click="toImgUrl(scope.row.url)" style="margin-left: 10px;" class="color"  type="text" slot="reference">查看处方{{ scope.$index+1   }}</el-button>
                </template>
              </el-table-column>
            </el-table>
      </el-dialog>

    </div>
  </template>
  <script>
  import leftNavigationBar from './components/leftNavigationBar.vue'
  import Header from '../components/header.vue'
  import mixin from "@/utils/mixin";
//   import jsPDF from 'jspdf';
  import html2canvas from 'html2canvas';
  import base from './api/base.js'
  import method from '../assets/js/util.js'
  
  export default {
    mixins: [mixin],
    components: {
      leftNavigationBar,
      Header,
      
    },
    data() {
      return {
        tableData: [],
        date:'',
        title:'订单详情',
        url:'',
        srcList:[],
        talk: [
        '麻烦快点',
        '催下医生',
        '催下药师',
        '医保看不到处方',
       
      ],
      quickConfig: {
        nav: ['',],
        showAdd: true,
        maxlength: 200,
        showHeader: true,
        showDeleteBtn: true,
      },
        config:{
            img: require("../assets/image/321.png"),
            name: "",
            dept: "",
        },
        Status:[
            '待审核',
            '审核中',
            '审核通过',
            '医生重新开方中，请稍等',
        ],
        state:{
            wait:'待审核',
            adopt:'已审核',
            quit:'已取消',
        },
        form:{
            DoctorInfo:{},
            Patient:[
                {
                    name:'',
                    phone:'',
                    sex:'',
                    address:'',
                }
            ]
        },
        chat_show:false,
        chatLIst:[],
        inputMsg:'',
        chatInfo:{},
        toolConfig:{
            show: ['file', ],
            callback: (type, plyload)=>{
                console.log('tools', type, plyload)
                this.uploadimg(plyload)
            }   
        },
        timer:null,
        input:'',
        presDrawer:false,
        presInfo:[]
      }
    },
    methods: {
        tableRowClassName({row, rowIndex}) {
           console.log(row,rowIndex)
           if(row.fromType == '2'){
            return 'success-row';
           }
           return '';
        },
        fromType(item){
            let txt = '无'
            if(item.fromType == '2' && item.take_drug_code !=='None'){
                txt = item.take_drug_code
            }
            return txt
        },
        handleClose(){
            this.drawer = false 
            this.presDrawer = false 
        },
        getpresImgUrl(item,key){
            if(item.ih_type == 'LodYXHospital' || item.ih_type == 'None'){
                let first10Chars = item.presImgUrl.substring(0, 7);
                if(first10Chars.indexOf('http') !==-1){
                    window.location.href = item.presImgUrl
                }else{
                    let data = {
                        data:item.presImgUrl
                    }
                    this.$api.article.aesurl(data).then(res=>{
                        console.log(res.data)
                        if(key == 'view'){
                            window.location.href = res.data.result
                        }
                        
                    })
                }
            }else{
                let params = {
                    search_db:'Pres'
                }
                let data = {
                    id:item.id,
                    pharmacy_id:item.pharmacy_id
                }
                this.$api.article.InternetHospitalGet(params,data,item.ih_type).then(res=>{
                    console.log(res)
                    if(res.data.result.data){
                        res.data.result.data.presInfo = JSON.parse(  res.data.result.data.presInfo)
                        console.log(res.data.result.data.presInfo)
                        if(key == 'view'){
                            if(res.data.result.data.presInfo.length>1){
                                this.presInfo = res.data.result.data.presInfo
                                this.presDrawer = true
                                
                            }else{
                                window.location.href = res.data.result.data.presInfo[0].url
                            }
                         
                        }
                    }
                })
            }
        
        },
        toImgUrl(url){
            window.open(url, '_blank')
        },
        rapidResponse(play){
            let inputMsg = {
                MSMediumText:play.value,
                type:'text',
                name:JSON.parse(localStorage.getItem('Pharmacy')).name
            }
            this.postChatList(JSON.stringify(inputMsg))

        },
        uploadimg(event){
            console.log(event)
            const file = event[0];
            const formData = new FormData();
            formData.append('file', file);
            this.$api.article.upload(formData).then(res=>{
                console.log(res)
                let inputMsg = {
                    MSMediumText:res.data.result.file_name,
                    type:'img',
                    name:JSON.parse(localStorage.getItem('Pharmacy')).name
                }
                this.postChatList(JSON.stringify(inputMsg))
            })
        },
        chatCli(item){
            console.log(JSON.parse(item.presInfo))
            this.config.name  = '患者：' +  JSON.parse(item.presInfo).patient.name
            this.apireturn('Complain','pres_id',item.id,).then(res=>{
                console.log(res)
                if(res.data.result.id){
                    this.chatInfo = res.data.result
                    this.chatGetList(this.chatInfo.id)
                    this.chat_show = true
                }else{
                    let params = {
                        search_db:'Complain',
                    }
                    let data = {
                        created:{
                            pres_id:item.id,
                            found_id:localStorage.getItem('userrole_id'),
                        }
                    }
                    this.$api.article.created(params,data).then(res=>{
                       this.chatInfo = res.data.result
                       this.chatGetList(this.chatInfo.id)
                       this.chat_show = true
                    })
                }
            })
        },
        //发送消息
        bindEnter(){
            let inputMsg = {
                MSMediumText:this.inputMsg,
                type:'text',
                name:JSON.parse(localStorage.getItem('Pharmacy')).name
            }
            this.postChatList(JSON.stringify(inputMsg))
        },
        postChatList(contents){
            let params = {
                search_db:'ComplainChat',
            }
            let data = {
                created:{
                    found_id:localStorage.getItem('userrole_id'),
                    chat_id:this.chatInfo.id,
                    contents:contents
                }
            }
            this.$api.article.created(params,data).then(res=>{
                console.log(res)
                this.chatGetList(this.chatInfo.id)
            })
        },
        chatGetList(id){
         if(!id){
                return
            }
            clearInterval(this.timer);
            console.log(id)
            let that = this
            let getApiChatList = function (){
                let params = {
                    search_db: 'ComplainChat',
                    query_type: 'equal',
                    order_by:'asc'
                };

                let data = {
                    query: [
                    
                    {
                        fild: { chat_id:id },
                        type: '=',
                        condition: '&&',
                    }
                    ],
                };
                that.$api.article.query2(params,data).then(res => {
                    let chatLIst = []
                    res.data.result.forEach(element => {
                        element.contents = JSON.parse(element.contents)
                        let  text = ''
                        if(element.contents.type == 'img'){
                            text = `<img data-src='${base.baseUrl}img?static_path=ComplainChat&userrole_id=${localStorage.getItem('userrole_id')}&token=${JSON.parse(localStorage.getItem('userinfo')).token}&file_name=${element.contents.MSMediumText}'/>`

                        }else{
                            text = element.contents.MSMediumText
                        }
                        chatLIst.push(
                            {
                                date:element.created_at,
                                text:{text:text},
                                name:element.contents.name,
                                mine:element.found_id == localStorage.getItem('userrole_id'),
                                img:'https://dan.net-sun.com/img/321.53bea19a.png'
                            }
                        )
                    });
                    that.chatLIst = chatLIst
                })
            }
            getApiChatList()
            this.timer = setInterval(function() {
                if(that.$route.name == 'Drugs'){
                    getApiChatList()
                }
            }, 2000);
        
        },
        AccelerationPres(type,item){
            let data = {
                name:type == 'Receiving'  ?  item.Patient[0].name : item.doctorId,
                pres_id:item.pres_id
            }
            this.$api.article.AccelerationPres(type,data).then(res=>{
                console.log(res)
                this.$message({
                message: type == 'Receiving' ? '催接单成功' : '催沟通成功',
                type: 'success'
                });
            })
        },
        PresPut(){
            let params = {
                search_db:'Pres',
                query_type:'first'
            }
            let data = {
                query:{
                    id:this.form.id
                },
                update:{
                    drugs_state:this.title == '订单审核'? 'adopt' : 'ok'
                }
            }
            this.$api.article.update(params,data).then(res=>{
                this.$message({
                    message: '操作成功',
                    type: 'success'
                });
                this.drawer = false;
                this.loadList();
            })
        },
        //创建
        establish() {  
        },
        chakan(item,title){
            this.form = {
                DoctorInfo:{},
                Patient:[
                    {
                        name:'',
                        phone:'',
                        sex:'',
                        address:'',
                    }
                ]
            }
            console.log(item)
            console.log(title)
            this.$api.article.query2(
                {
                    search_db: 'UserRole',
                    query_type: 'first'
                },
                {
                    query: [
                        {
                            fild: { id: item.found_id },
                            type: '=',
                            condition: '&&'
                        }
                    ],
                    tables_name: [
                        {
                            'name': 'User',
                            'table_name': 'User',
                            'my_fild': { 'user_id': 'user_id' }
                        }
                    ]
                }
            ).then(res => {
                console.log(res)
                if (res.data.result.User && res.data.result.User.length > 0) {
                    this.form.Patient[0].phone = res.data.result.User[0].user_account;
                    console.log(this.form.Patient[0].phone);
                    this.$forceUpdate(); // 强制更新
                } 
            });
            item['DoctorInfo'] = {}
            this.form = item
            this.title = title
           
            this.drawer = true
            if(item.doctorId !=='None'){
                this.apireturn('Doctor','doctor_id',item.doctorId).then(res=>{
                    this.$set(this.form.DoctorInfo,'name',res.data.result.name)
                    this.$set(this.form.DoctorInfo,'doctor_id',res.data.result.doctor_id)
                    this.$forceUpdate();

                })
            }
        },
        loadList() {
            let params = {
                    search_db: 'Pres',
                    query_type: `limit|${this.start}-${this.pageSize}`,
                    order_by:'desc'
                };
     
   
                let data = {
                    query: [
                   
                        {
                            fild: { pharmacy_id:localStorage.getItem('pharmacy_id')},
                            type: '=',
                            condition: '&&',
                        },
                        
                    ],
                };
                console.log(this.input)

                
                if(this.input !=='' && this.input.length == 5){
                    data.query.push(
                        {
                            fild: { take_drug_code: this.input },
                            type: '=',
                            condition: '&&',
                        }
                    )
                }else if(this.input !==''){
                    data.query.push(
                        {
                            fild: { patient_name:  this.input },
                            type: '=',
                            condition: '&&',
                        }
                    )
                }
                if(this.activeName !== 'all'){
                    data.query.push(
                        {
                        fild: { state: this.activeName, },
                        type: '=',
                        condition: '&&',
                        }
                    )
                }
                if(this.date !==''){
                    data.query.push(
                        {
                            fild: { created_at: this.date + ' 00:00:01' },
                            type: '>',
                            condition: '&&',
                        },
                        {
                            fild: { created_at: this.date + ' 23:59:59' },
                            type: '<',
                            condition: '&&',
                        }
                    )
                }
                console.log(data)
                this.$api.article.query2(params,data).then(res => {
                    console.log(res)
                    res.data.result.forEach(element => {
                        
                        element.DRGDatabase_name = JSON.parse(element.DRGDatabase_name)
                        element.DRGDatabas = '';
                        for (var i = 0; i < element.DRGDatabase_name.length; i++) {
                            element.DRGDatabas += element.DRGDatabase_name[i].name;
                            if (i < element.DRGDatabase_name.length - 1) {
                                element.DRGDatabas += ',';
                            }
                        }
                        element['presInfo'] = JSON.parse(JSON.stringify(element.pres_body))
                        element['Patient']  = [JSON.parse(element.pres_body).patient]
                        if(element.medicine == 'None'){
                           
                            element.pres_body = {
                                presInfo:{
                                    name:'无',

                                }
                            }
                            return
                        }
                        element.pres_body = {
                            presInfo:{
                                name:'无',
                            }
                        }
                        let medicine = JSON.parse(element.medicine)
                        element.medicine = JSON.parse(element.medicine)
                            element['Drugs'] = []
                            element['PublicDrugs'] = []
                            medicine.forEach(item => {
                                this.$api.article.query(
                                    {
                                        search_db:'PublicDrugs',
                                        query_type:'first'
                                    },
                                    {
                                        query:{
                                            id:item.skuId
                                        },
                                    }
                                ).then(res => {
                                    console.log(res)
                                    element.Drugs.push(res.data.result)
                                    element.PublicDrugs.push(res.data.result.name)
                                })
                        });
                    });
                    
                    this.tableData = res.data.result;
                    console.log(this.tableData)
                    this.total = res.data.total_count*1
                })
        
        },
        print(url){

        },
        // async generatePDF(item_url) {
        //     // 获取远程PDF的URL
        //     const pdfUrl = item_url;

        //     // 创建jsPDF实例
        //     const pdf = new jsPDF();

        //     // 获取PDF的页面大小
        //     const { width, height } = await this.getPageSize(pdfUrl);

        //     // 使用html2canvas将PDF转换为图像
        //     const canvas = await html2canvas(document.body, { width, height });

        //     // 将图像添加到PDF
        //     pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, width, height);

        //     // 保存或打印PDF
        //     pdf.autoPrint();
        //     const blob = pdf.output('blob');
        //     const url = URL.createObjectURL(blob);
        //     const iframe = document.createElement('iframe');
        //     iframe.style.display = 'none';
        //     iframe.src = url;
        //     document.body.appendChild(iframe);
        //     iframe.contentWindow.print();

        //     // 清理
        //     setTimeout(() => {
        //         document.body.removeChild(iframe);
        //         URL.revokeObjectURL(url);
        //     }, 100);
        // },

        async getPageSize(pdfUrl) {
            // 获取PDF的页面大小
            const response = await fetch(pdfUrl);
            const buffer = await response.arrayBuffer();
            const typedArray = new Uint8Array(buffer);
            const pdf = await pdfjsLib.getDocument(typedArray).promise;
            const page = await pdf.getPage(1);
            const { width, height } = page.getViewport({ scale: 1 });

            return { width, height };
        },
        getdate(){
            var d = new Date().getTime()
            this.date = method.formatDate(d).slice(0, 10)
            this.loadList()
        }
    },
    created() {
        
        
        if(this.$route.query.id){
            this.input = this.$route.query.id
        }
        this.getdate()
        
    }
  }
  </script>
  <style scopde lang="less">
    .search button{
        font-size: 15px;
        font-weight: 600;
    }
    .el-table .success-row {
        background: #f0f9eb;
    }
  </style>